import React from 'react';
import PropTypes from 'prop-types';
import AuthorsLandingPage from '@cleverrealestate/clever-components-v2/dist/components/AuthorsLandingPage';
import { useUserIPGrabber } from '@cleverrealestate/clever-helpers';
import SEO from '../components/meta/SEO';
import Layout from '../components/layout/Layout';

const AuthorsLandingTemplate = ({ pageContext }) => {
  useUserIPGrabber();
  const { authors, title, metaDescription, metaTitle } = pageContext;

  return (
    <Layout id="page-author-landing">
      <SEO
        pathname="/authors/"
        title={metaTitle}
        description={metaDescription}
      />
      <AuthorsLandingPage authors={authors} title={title} />
    </Layout>
  );
};

AuthorsLandingTemplate.propTypes = {
  pageContext: PropTypes.any,
};

AuthorsLandingTemplate.defaultProps = {
  pageContext: {},
};

export default AuthorsLandingTemplate;
